<template>
  <div id="app">
    <transition name="fade-transform" mode="out-in">
      <router-view v-if="isRouterAlive"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  // 刷新
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true //控制刷新
    };
  },
  created() {
    // document.title = "功能授权";
    this.init();
  },
  methods: {
    // 刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    // 初始化
    init() {
    }
  }
};
</script>

<style>
/*@import "./assets/css/specialReport.less";*/
/*body, div {*/
/*  font-size: 12px !important;*/
/*}*/
</style>


